<template>
  <div class="layout">
    <div class="background">
      <div class="title" :style="{ color: res.list[0].color }">
        {{ res.list[0].title }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    title: '标题栏',
    props: ['res'],
  }
</script>
<style lang="scss" scoped>
  @import './tpl.scss';
  .background {
    position: absolute;
    z-index: 2;
    width: 100%;

    height: 42px;
    background: url('../../../assets/title.png') no-repeat;
    background-size: cover;
  }
  .layout {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    text-align: center;

    background: #ffffff;
  }
  .title {
    font-size: 15px;
    font-weight: bold;
    line-height: 42px;
  }
</style>
